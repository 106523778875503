<template>
	<div class="page container">
		<v2-back-btn :route="`/teaching/deals`" />
		<div class="page__info">
			<div class="page__info--title">
				View deal
			</div>
		</div>

		<div class="separator" />

		<div class="content">
			<div class="main">
				<list-view
					:list="getList"
				/>
				<div data-element="player-position-field">
					<validation-observer
						data-element="form"
						tag="form"
						ref="form"
					>
						<select-field
							:searchIsLoading="isUpdating"
							:disabled="isUpdating"
							v-if="isPlayerPositionFieldVisible"
							name="Player position"
							:options="getPlayerPositionOptions"
							:emptyAllowed="false"
							v-model="playerPosition"
						/>
						<alert
							v-if="isChangePlayerPositionAlertVisible"
							text="Changing player side will reset bid hints. Continue?"
						>
							<template #action>
								<div data-element="player-position-alert-actions">
									<V2Button
										@click="approvePlayerPositionChange"
										size="sm"
										variant="white"
										fit
									>
										Yes
									</V2Button>
									<V2Button
										@click="cancelPlayerPositionChange"
										size="sm"
										variant="white"
										fit
									>
										Cancel
									</V2Button>
								</div>
							</template>
						</alert>
					</validation-observer>
				</div>
			</div>
			<div class="side">
				<div class="deal__side--info">
					<div class="deal__side--info-title">
						<icon icon="v2-deal-settings" colour="transparent" />
						Edit
					</div>

					<div class="deal__side--info-btns">
						<actions
							:actions="getFirstRowActions"
							@actionClick="onActionClick"
						/>
						<div class="separator" />
						<actions
							:actions="getSecondRowActions"
							@actionClick="onActionClick"
						/>
						<div class="separator" />
						<actions
							:actions="getThirdRowActions"
							@actionClick="onActionClick"
						/>
						<div class="separator" />
						<actions
							:actions="getFourthRowActions"
							@actionClick="onActionClick"
						/>
					</div>
				</div>

				<div class="deal__side--info" v-if="hasDeal">
					<div class="deal__side--info-title">
						<icon icon="v2-gameplay" colour="transparent" />
						Gameplay
					</div>

					<div class="deal__side--info-summary">
						<deal-summary
							ref="dealSummary"
							:deal="deal"
						/>
					</div>
				</div>

			</div>
		</div>

		<portal
			to="modal"
			v-if="deleteConfirmationIsVisible"
		>
			<modal-inner
				@close="toggleDeleteConfirmation"
				heading="Delete deal"
			>
				<confirmation-modal
					text="Deleting the deal will permanently remove it from the deal library. Deleted deals will still be available in deal run throughs."
					confirmationText="Delete"
					confirmationActionId="deleteDeal"
					cancelActionId="toggleDeleteConfirmation"
					@actionClick="onActionClick"
				/>
			</modal-inner>
		</portal>
		<portal
			to="modal"
			v-if="editDealRecordingIsVisible"
		>
			<modal-inner
				@close="toggleEditDealRecording"
				heading="Deal run-through recording"
			>
				<edit-deal-recording-modal
					:deal="deal"
					@recordDealRecording="recordDealRecording"
					@close="toggleEditDealRecording"
				/>
			</modal-inner>
		</portal>
		<portal
			to="modal"
			v-if="uploadDealAnalysisIsVisible"
		>
			<modal-inner
				@close="toggleUploadDealAnalysis"
				size="sm"
				heading="Upload deal analysis"
			>
				<upload-deal-analysis-modal
					:deal="deal"
					@close="toggleUploadDealAnalysis"
				/>
			</modal-inner>
		</portal>
	</div>
</template>

<script>
	import ListView from '@/components/ui/views/listView/ListView';
	import Alert from '@/components/ui/Alert';
	import SelectField from '@/components/forms/SelectField';
	import Actions from '@/components/ui/Actions';
	import DealSummary from '@/components/diagrams/DealSummary';
	import ModalInner from '@/components/ui/modal/ModalInner';
	import ConfirmationModal from '@/components/ui/modal/ConfirmationModal';
	import EditDealRecordingModal from '@/components/dealLibrary/EditDealRecordingModal';
	import UploadDealAnalysisModal from '@/components/dealLibrary/UploadDealAnalysisModal';
	import api from '@/services/api';
	import actionClick from '@/mixins/actionClick';
	import { dealAnalysisType, dealRecordingType, SEATS } from '@/consts';
	import dayjs from 'dayjs';
	import relativeTime from 'dayjs/plugin/relativeTime';
	import Icon from '@/components/ui/Icon';
	import V2BackBtn from '@/components/v2/ui/V2BackBtn';
	import V2Button           from '@/components/v2/ui/V2Button';

	dayjs.extend(relativeTime);

	export default {
		layout: 'v2default',

		components: {
			ListView,
			Alert,
			SelectField,
			Actions,
			DealSummary,
			ModalInner,
			ConfirmationModal,
			EditDealRecordingModal,
			UploadDealAnalysisModal,
			Icon,
			V2BackBtn,
			V2Button
		},
		mixins: [actionClick],
		data: () => ({
			deal: undefined,
			hasDeal: false,
			deleteConfirmationIsVisible: false,
			editDealRecordingIsVisible: false,
			uploadDealAnalysisIsVisible: false,
			playerPosition: null,
			isPlayerPositionFieldVisible: false,
			isChangePlayerPositionAlertVisible: false,
			isUpdating: false,
			previousDealId: null,
			nextDealId: null
		}),
		computed: {
			getDealId () {
				return this?.deal?.id;
			},
			getDealIsFirstDealInQuery () {
				return this.$store.getters['dealLibrary/getDealIsFirstDealInQueryById']({
					id: this.getDealId
				});
			},
			getDealIsLastDealInQuery () {
				return this.$store.getters['dealLibrary/getDealIsLastDealInQueryById']({
					id: this.getDealId
				});
			},
			getCrumbRoute () {
				return '/teaching/deals';
			},
			getList () {
				if (!this.deal) {
					return false;
				}
				return [
					{
						text: `Name: ${this.deal.name}`
					},
					(this.deal.classified ?
						{
							text: 'Classified'
						} : false
					),
					{
						text: `Date imported:  ${dayjs(this.deal.importDate).format('DD/MM/YYYY')}`
					},
					(this.deal.experienceLevel ?
						{
							text: `Experience level: ${this.deal.experienceLevel}`
						} : false
					),
					(this.deal.biddingSystems === null ?
						{
							text: 'Bidding systems: Any'
						} : false
					),
					(this.deal.biddingSystems?.length ?
						{
							text: `Bidding systems: ${this.deal.biddingSystems.join(',')}`
						} : false
					),
					(this.deal.importOrder !== undefined ?
							{
								text: `Import order: ${this.deal.importOrder}`
							} : false
					),
					(this.deal.playerPosition !== undefined ?
							{
								text: `Player position: ${this.deal.playerPosition}`
							} : false
					),
					(this.deal.altDealer?.length ?
						{
							text: `Alt Dealer: ${this.deal.altDealer?.join(',')}`
						} : false
					),
					(this.deal.parTricks ?
						{
							text: `Par tricks: ${this.deal.parTricks}`
						} : false
					),
					(this.deal.focus?.length ?
						{
							text: `Focus: ${this.deal.focus?.join(',')}`
						} : false
					),
					(this.getDealRecordingExists ?
						{
							icon: 'check',
							iconColor: 'green',
							text: 'Run-through recording:'
						} : false
					),
					(this.getDealAnalysis ?
							{
								icon: 'document',
								iconColor: 'grey',
								text: 'Deal analysis:',
								secondary: this.getDealAnalysis.originalname
							} : false
					),
					(this.deal.keywords?.length ?
						{
							text: `Keywords: ${this.deal.keywords?.join(',')}`
						} : false
					),
					(this.deal.tags?.length ?
						{
							text: `Tags: ${this.deal.tags?.join(',')}`
						} : false
					),
					(this.deal.ddsPrioritizesLowestCards ?
							{
								icon: 'check',
								iconColor: 'green',
								text: 'DDS should prioritize the lowest cards:'
							} : false
					)
				].filter(Boolean);
			},
			getFirstRowActions () {
				return {
					primary: [
						{
							text: 'Edit',
							actionId: 'editDeal',
							variant: 'red'
						}
					].filter(Boolean)
				};
			},
			getSecondRowActions () {
				return {
					primary: [
						(
							this.previousDealId ? {
								text: 'Previous deal',
								actionId: 'navigateToPreviousDeal'
							} : false
						),
						(
							this.nextDealId ? {
								text: 'Next deal',
								actionId: 'navigateToNextDeal'
							} : false
						)
					].filter(Boolean)

				};
			},
			getThirdRowActions () {
				return {
					primary: [
						{
							text: `${this.getDealRecordingExists ? 'Edit' : 'Create'} run-through recording`,
							actionId: 'toggleEditDealRecording'
						},
						{
							text: 'Upload deal analysis',
							actionId: 'toggleUploadDealAnalysis'
						}
					]
				};
			},
			getFourthRowActions () {
				return {
					primary: [
						{
							text: 'Delete',
							actionId: 'toggleDeleteConfirmation',
							variant: 'outline'
						}
					]
				};
			},
			getBreadcrumbs () {
				return [
					{
						path: '/teaching',
						text: 'Teaching'
					},
					{
						path: '/teaching/deals',
						text: 'Deal library'
					},
					{
						path: `/teaching/deals/${this.getDealId}`,
						text: this.deal.name
					}
				];
			},
			getDealRecordingExists () {
				if (this.deal?.media?.length) {
					return this.deal.media.find(item => item.config.type === dealRecordingType);
				}
				return false;
			},
			getRecordingInProgress () {
				return this.$store.getters['screenRecorder/getRecordingInProgress'];
			},
			getDealAnalysis () {
				return this?.deal?.media.find(item => item.config?.type === dealAnalysisType);
			},
			getControlsVisible () {
				return this.$store.getters['screenRecorder/getControlsVisible'];
			},
			getDealHintsValues () {
				return this.deal?.bidHints.map(item => item.hint).filter(Boolean) || [];
			},
			getPlayerPositionOptions () {
				const declarerSeat = SEATS[this.deal.declarer];
				const pair = declarerSeat.pair.split('');
				return Object.values(SEATS)
					.filter(seat => pair.includes(seat.short))
					.map(seat => ({
						text: seat.name,
						value: seat.short
					}));
			},
			getDealContext () {
				return this.$route?.query.context || 'library';
			},
			getDealLessonId () {
				return this.$route.query?.lessonId;
			},
			getDealQuery () {
				const query = {
					context: this.getDealContext
				};

				if (this.getDealLessonId) {
					query.lessonId = this.getDealLessonId;
				}

				return query;
			}
		},
		watch: {
			getRecordingInProgress (inProgress, wasInProgress) {
				if (wasInProgress && !inProgress && this.$refs.dealSummary?.simulatorIsVisible) {
					// Close the simulator when the screen recording is complete
					this.$refs.dealSummary.closeSimulator();
				}
			},
			getControlsVisible (visible, wereVisible) {
				if (wereVisible && !visible && this.$refs.dealSummary?.simulatorIsVisible) {
					// Close the simulator when the user cancels deal recording
					this.$refs.dealSummary.closeSimulator();
				}
			},
			playerPosition (value, oldValue) {
				if (
					oldValue &&
					oldValue !== value
				) {
					if (this.getDealHintsValues.length > 0) {
						this.isChangePlayerPositionAlertVisible = true;
					} else {
						this.deal.playerPosition = value;
					}
				}
			},
			'deal.playerPosition' (value, oldValue) {
				if (value && oldValue && this.isPlayerPositionFieldVisible) {
					this.updateDeal();
				}
			}
		},
		created () {
			this.setDeal().then(() => {
				this.fetchPreviousAndNextDeals();
				this.$watch(
					() => (this.$refs.dealSummary?.simulatorIsVisible),
					(value, oldValue) => this.handleSimulatorToggle(value, oldValue)
				);
			});
		},
		methods: {
			approvePlayerPositionChange () {
				this.deal.playerPosition = this.playerPosition;
				this.isChangePlayerPositionAlertVisible = false;
			},
			cancelPlayerPositionChange () {
				this.isPlayerPositionFieldVisible = false;
				this.playerPosition = this.deal.playerPosition;
				this.$nextTick(() => {
					this.isPlayerPositionFieldVisible = true;
					this.isChangePlayerPositionAlertVisible = false;
				});
			},
			async fetchPreviousAndNextDeals () {
				const { prev, next } = await api.deals.getPreviousAndNextDeals({
					dealId: this.getDealId,
					context: this.getDealContext,
					lessonId: this.getDealLessonId
				});

				if (prev) {
					this.previousDealId = prev;
				}

				if (next) {
					this.nextDealId = next;
				}
			},
			async updateDeal () {
				this.isUpdating = true;
				const valid = await this.$refs.form.validate();
					if (!valid) {
						this.showInvalidFormMessage();
						return;
					}
				const deal = await api.deals.updateDeal({
					dealId: this.$route.params.dealId,
					deal: this.deal
				});
				if (deal) {
					this.deal = deal;
					this.$store.commit('ui/showNotification', {
						notification: 'The deal has been updated'
					});
				} else {
					console.error('Something went wrong. Empty response received.');
				}
				this.isUpdating = false;
			},
			navigateToPreviousDeal () {
				this.$router.push({
					path: `/teaching/deals/${this.previousDealId}`,
					query: this.getDealQuery
				});
			},
			navigateToNextDeal () {
				this.$router.push({
					path: `/teaching/deals/${this.nextDealId}`,
					query: this.getDealQuery
				});
			},
			editDeal () {
				this.$router.push({
					path: `/teaching/deals/${this.deal.id}/edit`,
					query: this.$route.query
				});
			},
			async setDeal () {
				const deal = await api.deals.getDeal({
					dealId: this.$route.params.dealId
				});
				if (!deal) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.deal = deal;
				this.hasDeal = true;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
				if (this.deal.playerPosition) {
					this.playerPosition = this.deal.playerPosition;
					this.isPlayerPositionFieldVisible = true;
				}
			},
			toggleDeleteConfirmation () {
				this.deleteConfirmationIsVisible = !this.deleteConfirmationIsVisible;
			},
			async deleteDeal () {
				const deal = await api.deals.deleteDeal({
					dealId: this.$route.params.dealId
				});
				if (!deal) {
					this.$store.commit('ui/showError');
					return false;
				}
				await this.$router.push(this.getCrumbRoute);
				this.$store.commit('ui/showNotification', {
					notification: 'Deal has been removed.'
				});
			},
			toggleEditDealRecording () {
				this.editDealRecordingIsVisible = !this.editDealRecordingIsVisible;
			},
			toggleUploadDealAnalysis () {
				this.uploadDealAnalysisIsVisible = !this.uploadDealAnalysisIsVisible;
			},
			recordDealRecording () {
				this.$refs.dealSummary.showSimulator();
				this.$store.dispatch('screenRecorder/showControls');
			},
			handleSimulatorToggle (value, oldValue) {
				if (oldValue && !value) {
					this.$store.dispatch('screenRecorder/hideControls');
				}
			}
		}
	};

</script>

<style lang="scss" scoped>

.content {

  .main {
    width: calc(100% - 30px - 425px)
  }

  .side {
    width: 425px;
  }

  @media (max-width: 1279px) {
    display: flex;
    flex-direction: column;

    .main,
    .side {
      width: 100%;
    }
  }
}

.deal {

  &__side {

    &--info {
      display: flex;
      flex-direction: column;

      &-title {
        display: flex;
        align-items: center;
        padding: 7.5px 0;
        margin-bottom: 12px;
        gap: 10px;
        @include font(18px, $c-darkest-navy, 700, 23px);
      }

      &-summary {
        ::v-deep [data-component="hand"] {
          border: none;
        }

        ::v-deep [data-component="deal-matrix"] {
          background: $c-white-l;
          border-radius: 4px;
        }

        ::v-deep [data-component="bidding-table"] {
          background: $c-white-l;
          border-radius: 4px;
          width: 100%;

          [data-element="table"] {
            width: 100%;
          }
        }
      }

      &-btns {
        display: flex;
        flex-direction: column;
        gap: 6px;
        margin-bottom: 36px;

        ::v-deep [data-component="actions"] {
          display: flex;
          flex-direction: column;
          padding: 0;

          [data-component="btn"] {
            width: 100%;
          }

          [data-element="actions-primary"] {
            display: flex;
            flex-direction: column;
            gap: 6px;
            margin: 0;
          }
        }

        .separator {
          margin: 6px 0;
        }
      }
    }
  }
}
</style>
